.App {
  font-family: Arial, sans-serif;
  padding: 20px 100px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th, td {
  padding: 12px;
  border: 1px solid #ddd;
}

th {
  background-color: #f4f4f4;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.filters {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.filters input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  grid-column: 1; /* Make the search input span two columns */
  width: 480px;
  height: 20px;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.pagination li {
  display: inline;
  margin: 0 5px;
}

.pagination li a {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  text-decoration: none;
}

.pagination li a:hover {
  background-color: #f4f4f4;
}

.pagination .active a {
  background-color: #ddd;
  pointer-events: none;
}

.footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f4f4f4;
  text-align: center;
  border-top: 1px solid #ddd;
}

.footer p {
  margin: 5px 0;
}